import { parse } from "node-html-parser";
import React, { ReactElement, useEffect } from "react";

import Section from "components/Section";
import {
    addEditAttributes,
    addEditAttributesBlockLevel,
} from "utils/episerver";

import { StyledEmbed } from "./Embed.styled";
import EmbedProps from "./EmbedProps";

const Embed = ({
    contentId,
    identifier,
    content,
    className,
    anchorName,
}: EmbedProps): ReactElement => {
    const embedRef = React.createRef<HTMLDivElement>();

    const contentRoot = content ? parse(content) : undefined;
    const scripts = contentRoot ? contentRoot.querySelectorAll("script") : [];

    const innerContent =
        (content &&
            content.replace(
                /(<|%3C)script[\s\S]*?(>|%3E)[\s\S]*?(<|%3C)(\/|%2F)script[\s\S]*?(>|%3E)/gi,
                "",
            )) ||
        "";

    useEffect(() => {
        if (embedRef.current) {
            const reference = embedRef.current;

            const documentScripts = scripts.map((scriptContent) => {
                const script = document.createElement("script");

                const scriptSrc =
                    scriptContent?.getAttribute("src") || undefined;
                const scriptInnerHTML = scriptContent?.innerHTML || undefined;

                if (scriptSrc) script.src = scriptSrc;
                if (scriptInnerHTML) script.innerHTML = scriptInnerHTML;
                script.async = true;
                return script;
            });

            documentScripts.forEach(
                (script) => reference && reference.appendChild(script),
            );

            return () => {
                documentScripts.forEach(
                    (script) => reference && reference.removeChild(script),
                );
            };
        }
    });

    return (
        <>
            <Section
                {...addEditAttributesBlockLevel(contentId)}
                id={anchorName}
            >
                <StyledEmbed
                    className={className}
                    id={identifier}
                    ref={embedRef}
                    dangerouslySetInnerHTML={{
                        __html: innerContent || "",
                    }}
                    {...addEditAttributes("Content")}
                />
            </Section>
        </>
    );
};

export default React.memo(Embed);
