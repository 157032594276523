import { styled } from "styled-components";

import { Container } from "style/components/Page";

export const StyledEmbed = styled(Container)`
    place-self: center center;
    width: 100%;

    &.embedded {
        padding: 1.3125rem 0;
    }
`;
